"use client";

import React from "react";
import { Announcement, Content, Footer, ProvidersList, GamesListProvider, ProvidersUFA, LeaderBoard } from "@/sections";
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import FloatingLineContact from "@/sections/FloatingLineContact";
import { getProviderConfig } from "../libs/utils";
import { useMediaQuery, useTheme } from "@mui/material";
import { Icon } from "@iconify/react";
import { useAppModalStore } from "@/stores/appModalStore";
import { shallow } from "zustand/shallow";
import { useAuthStore } from "@/stores/authStore";

const settings = [
  { key: "Profile", text: "โปรไฟล์", icon: "mdi:person-outline" },
  { key: "historyAll", text: "ประวัติธุรกรรม", icon: "material-symbols:history" },
  { key: "refund", text: "ยอดเสีย", icon: "gridicons:refund" },
  { key: "affiliate", text: "แนะนำเพื่อน", icon: "mdi:invite" },
  { key: "coupon", text: "คูปอง", icon: "mdi:coupon-outline" },
  { key: "wheel", text: "กงล้อ", icon: "lucide:ferris-wheel" },
  { key: "promotion", text: "โปรโมชั่น", icon: "ph:gift-fill" },
  { key: "mysteryBox", text: "กล่องสุ่ม", icon: "ph:archive-bold" },
  { key: "changePassword", text: "เปลี่ยนรหัสผ่าน", icon: "mdi:id-card" },
  { key: "Logout", text: "ออกจากระบบ", icon: "mdi:logout" },
];

export default function MainPage() {
  const theme = useTheme();
  const [user, logout] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );
  const isLoggedIn = user != null;

  const providerConfig = getProviderConfig();
  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleProfileMenuClick = (key: string) => {
    if (key == "Logout") {
      logout();
    } else {
      setOpen(true);
      setData(key);
    }
  };

  return (
    <Box>
      {isMobile && (
        <>
          <Grid item xs={12}>
            <Announcement />
            <Content />
            {providerConfig ? (providerConfig === 'UFA' ? <ProvidersUFA /> : <GamesListProvider provider={providerConfig} />) : <ProvidersList />}
            <LeaderBoard />
            <Footer />
            <FloatingLineContact />
          </Grid>
        </>
      )}
      {!isMobile && (
        <>
        <Box>
        {isLoggedIn && (
          <Grid container>

            <Grid item xs={1}> 
            {/* MenuNew */}
              <List 
              sx={{
                display: 'flex', flexDirection: 'column', 
                alignItems: 'center', gap: 0 ,position: "sticky",
                top: 0
                }}>
                {settings.map((setting) => (
                  <ListItem
                    button
                    key={setting.key}
                    onClick={() => handleProfileMenuClick(setting.key)}
                    sx={{
                      display: 'flex',
                      // backgroundColor: "rgba(58, 55, 79, 0.5)",
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '80%',
                    }}
                  >
                    <ListItemIcon sx={{ color: theme.palette.primary.contrastText, fontSize: 30, display: 'flex', justifyContent: 'center' }}>
                      <Icon icon={setting.icon} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="caption" align="center">{setting.text}</Typography>} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={11}>
              <Announcement />
              <Content />
              {providerConfig ? (providerConfig === 'UFA' ? <ProvidersUFA /> : <GamesListProvider provider={providerConfig} />) : <ProvidersList />}
              <LeaderBoard />
              <Footer />
              <FloatingLineContact />
            </Grid>
          </Grid>
        )}
        {!isLoggedIn && (
          <Grid item xs={12}>
          <Announcement />
          <Content />
          {providerConfig ? (providerConfig === 'UFA' ? <ProvidersUFA /> : <GamesListProvider provider={providerConfig} />) : <ProvidersList />}
          <LeaderBoard />
          <Footer />
          <FloatingLineContact />
        </Grid>
        )}
        </Box>
          
        </>
      )}
    </Box>
  );
}
